import React from 'react';
import '../css/home.css';
import { Avatar } from '@material-ui/core';
import hero from '../static/images/avatar/potraitSmile-bw-1.jpg';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'inline',
    '& > *': {
      margin: theme.spacing(4),
    },
  },
  large: {
    width: theme.spacing(50),
    height: theme.spacing(50),
    marginLeft: 'auto',
    marginRight: 'auto',
    transition: 'opacity 400ms ease 0ms'
  },
  thumb: {
    filter: 'blur(1000px)',
    transform: 'scale(1.1)',
    transition: 'visibility 0ms ease 400ms'
  }
}));

function Home() {
  const classes = useStyles();
  const [isLoaded, setIsLoaded] = React.useState(false);

  return (
    <span>
        <div className={classes.root}>
          <h1 class="site-title">Jorge Rangel</h1>
          <Avatar
            src={hero} className={classes.large}
          />
          <div class="intro-text">
            <p>Hi I'm Jorge,<br/><br/>
              Software Engineer with a passion for travel, technology, the outdoors, and fitness.<br/><br/>

              Seeking to build tools that'll have a meaningful impact, to work hard for things that are
              worth it, and to surround myself with people who bring out the best of me.<br/><br/>
            </p>
          </div>
          <div class="intro-quote-text">
            <p>“Only you can master your mind, which is what it takes to live a bold life filled with accomplishments
              most people consider beyond their capability.”<br></br>- David Goggins
            </p>
          </div>
        </div>
    </span>
  )
}

export default Home;
