import React from 'react';
import './css/app.css';
import Home from './components/Home';
import Content from './components/Content';
import { Typography, Grid, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  Switch,
  Route,
  Link as RouterLink
} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
}));

function App() {
  const classes = useStyles();
  return (
    <main className={classes.root}>
      <Grid container spacing={2} alignItems="center" justify="center" direction="row">
        <Grid item xs="12">
          <Typography id="globalnav">
            <div>
              <Link component={RouterLink} class="gn-link" to="/about">
                <span class="gn-link-text">About me</span>
              </Link>
              <Link component={RouterLink} class="gn-link" to="/content">
                <span class="gn-link-text">Content</span>
              </Link>
            </div>
          </Typography>
        </Grid>
      </Grid>

      <Switch>
        <Route exact path="/" component={Home}/>
        <Route exact path="/about" component={Home}/>
        <Route path="/content" component={Home}/>
      </Switch>
    </main>
  )
}

export default App;

function Test() {
  return <h2>hello world</h2>
}
